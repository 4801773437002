* {
  margin: 0;
}

.App {
  font-size: 15px;
  font-family: "Cantarell", sans-serif;
  color: white;
  flex-direction: column;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(20, 20, 25);
  text-align: center;
}
